<template>
  <div>
    <LiefengContent :isBack='true' @backClick="backClick">
      <template #title>群公告记录
        <!-- <Button type="error" @click="backBtn">返回</Button> -->
      </template>
      <template #contentArea>
        <div class="container">
          <LiefengTable
            :tableData="tableData"
            :talbeColumns="talbeColumns"
            :loading="loading"
            :pageSize="pageSize"
            :pagesizeOpts="[20, 30, 50, 100, 200]"
            :total="total"
            :curPage="page"
            @hadlePageSize="hadlePageSize"
            :fixTable="true"
          ></LiefengTable>
        </div>
      </template>
    </LiefengContent>
    <LiefengModal
      title="谁看过"
      :fullscreen="true"
      :value="batchModal"
      @input="batchModallFn"
      class="check"
    >
      <template v-slot:contentarea>
        <div class="check-div">
          <div class="div-item">接受总人数: {{nunList.totalCount || 0}}</div>
          <div class="div-item">已读人数: {{nunList.readCount || 0}}</div>
          <div class="div-item">
            未读人数:
            <span style="color:red;text-decoration: underline;">{{nunList.unreadCount || 0}}</span>
          </div>
          <!-- <div class="div-item">未读房号数: {{'0'}}</div> -->
        </div>
        <LiefengTable
          :tableData="tableCheckData"
          :talbeColumns="talbeCheckColumns"
          :loading="checkLoading"
          :pageSize="checkPageSize"
          :pagesizeOpts="[20, 30, 50, 100, 200]"
          :total="checkTotal"
          :curPage="checkPage"
          @hadlePageSize="hadleCheckPageSize"
          :fixTable="false"
          class="check-table"
        ></LiefengTable>
      </template>
      <template v-slot:toolsbar>
        <Button type="primary" @click="batchModallFn(false)">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template>


<script>
//@route('/groupnotice')
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
export default {
  data() {
    return {
      tableData: [],
      talbeColumns: [
        {
          title: "群名称",
          key: "groupName",
          minWidth: 140,
          align: "center"
        },
        {
          title: "内容",
          key: "content",
          minWidth: 200,
          align: "center"
        },
        {
          title: "发送人",
          key: "creator",
          minWidth: 80,
          align: "center"
        },
        {
          title: "发送时间",
          key: "gmtCreate",
          minWidth: 100,
          align: "center"
        },
        {
          title: "操作",
          width: 300,
          align: "center",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  display: "flex",
                  justifyContent: "center"
                }
              },
              [
                // h(
                //   "Button",
                //   {
                //     style: {
                //       marginRight: "10px"
                //     },
                //     props: {
                //       type: "primary"
                //     },
                //     nativeOn: {
                //       click: () => {
                //         if (params.row.mobile != 1) {
                //           this.setBtn();
                //         } else {
                //           this.noneBatch(params.row);
                //         }
                //       }
                //     }
                //   },
                //   "查看详情"
                // ),
                h(
                  "Button",
                  {
                    props: {
                      type: "warning"
                    },
                    style: {
                      marginRight: "10px"
                    },
                    nativeOn: {
                      click: () => {
                        this.checkWho(params.row);
                      }
                    }
                  },
                  "谁看过"
                )
                // h(
                //   "Button",
                //   {
                //     props: {
                //       type: "warning"
                //     }
                //   },
                //   "删除"
                // )
              ]
            );
          }
        }
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      curPage: 0,
      batchModal: false, //谁看过模态框事件
      cancelModalTip: false,
      tableCheckData: [],
      checkLoading: false,
      checkPageSize: 20,
      checkPage: 1,
      checkTotal: 0,
      talbeCheckColumns: [
        {
          title: "社区",
          key: "communityName",
          minWidth: 80,
          align: "center"
        },
        // {
        //   title: "街巷",
        //   key: "streetName",
        //   minWidth: 80,
        //   align: "center"
        // },
        // {
        //   title: "小区",
        //   key: "projectName",
        //   minWidth: 80,
        //   align: "center"
        // },
        // {
        //   title: "楼栋",
        //   key: "buildingName",
        //   minWidth: 80,
        //   align: "center"
        // },
        {
          title: "详细地址",
          key: "houseNum",
          minWidth: 80,
          align: "center"
        },
        {
          title: "姓名",
          key: "name",
          minWidth: 80,
          align: "center"
        },
        {
          title: "手机号",
          key: "phone",
          minWidth: 80,
          align: "center"
        },
        {
          title: "查看时间",
          key: "latestTime",
          minWidth: 80,
          align: "center"
        },
        {
          title: "查看次数",
          key: "viewCount",
          minWidth: 80,
          align: "center"
        }
      ],
      nunList: {
        readCount: 0,
        totalCount: 0,
        unreadCount: 0
      }
    };
  },

  methods: {
    //   方法部分
    // 返回按钮去
    backClick() {
      this.$router.push({ path: "/groupindex",query:{isLineHome:this.$route.query.isLineHome} });
    },
    // 谁看过的分页
    hadleCheckPageSize(obj) {
      this.getWhoCheckList(obj);
    },

    // 分页回调事件
    hadlePageSize(obj) {
      this.getList(obj);
    },
    // 关闭模态框方法
    batchModallFn(status) {
      this.batchModal = status;
      this.checkPageSize = 20;
      this.checkPage = 1;
    },
    // 点击我是谁按钮
    checkWho(row) {
      this.batchModal = true;
      let data = {
        afficheId: row.id,
        page: 1,
        pageSize: 20
      };
      this.getWhoCheckList(data);
      this.getNumList(row.id);
    },
    // 接口部分
    getList(data) {
      this.$get("/sendmsg/pc/chat/groupAffiche/queryPage", {
        oemCode: parent.vue.oemInfo.oemCode,
        operatorGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        operatorName: parent.vue.loginInfo.userinfo.realName,
        operatorOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: data.page,
        pageSize: data.pageSize
      }).then(res => {
        let data = res.dataList;
        data.map(item => {
          item.gmtCreate = this.$core.formatDate(
            new Date(item.gmtCreate),
            "yyyy-MM-dd hh:mm:ss"
          );
        });
        this.tableData = data;
        this.total = res.maxCount;
        this.curPage = res.currentPage;
      });
    },
    // 点击谁看过的list表
    getWhoCheckList(data) {
      this.$get("/sendmsg/pc/chat/groupAffiche/queryAfficheViewPage", {
        afficheId: data.afficheId,
        page: data.page,
        pageSize: data.pageSize
      }).then(res => {
        let data = res.dataList;
        data.map(item => {
          item.latestTime = this.$core.formatDate(
            new Date(item.latestTime),
            "yyyy-MM-dd hh:mm:ss"
          );
        });
        this.tableCheckData = data;
        this.checkTotal = res.maxCount;
      });
    },
    // 获取数量统计列表
    getNumList(data) {
      this.$get("/sendmsg/pc/chat/groupAffiche/statAfficheViewCount", {
        afficheId: data
      }).then(res => {
        let data = res.data;
        this.nunList.readCount = data.readCount;
        this.nunList.totalCount = data.totalCount;
        this.nunList.unreadCount = data.unreadCount;
      });
    }
  },
  created() {
    let data = {
      page: 1,
      pageSize: 20
    };
    this.getList(data);
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal
  }
};
</script>

<style scoped lang="less">
.check {
  .check-div {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 14px;
    .div-item {
      margin-right: 20px;
      line-height: 40px;
    }
  }
}

.check-table {
  min-height: 660px;
  /deep/.ivu-table-tip {
    height: 660px;
  }
  /deep/.ivu-table-wrapper {
    height: 660px;
  }
}
</style>